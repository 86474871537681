import React from 'react'
import classnames from 'classnames'
import styles from './ContentSection.module.scss'
import { Button } from '../'

const ContentSection = ({ title, image, html, color, link }) => {
  return (
    <div className={classnames(styles.container, 'container')}>
      <div className='row'>
        <div className='offset-md-1 col-lg-9'>
          <h2 style={{ color }}>{title}</h2>
        </div>
      </div>
      <div className='row'>
        {image && (
          <div className='col-md-5 px-4 d-none d-md-block mt-5'>
            <img src={image} alt='' className={classnames('img-fluid', styles.image)} />
          </div>
        )}
        <div className={classnames({
          'offset-md-2': !image,
          'col-md-8': !image,
          'col-md-7': image
        })}>
          <div dangerouslySetInnerHTML={{
            __html: html
          }} />
          {link.href && (
            <Button className='mt-3' to={link.href}>{link.title}</Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContentSection
