import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './BackgroundWrapper.module.scss'
import { Parallax } from 'react-scroll-parallax'

class BackgroundWrapper extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    setTimeout(() => {
      this.context.parallaxController.update()
    }, 50)
  }

  render () {
    const { elements, children } = this.props

    return (
      <div className={styles.container}>
        {elements.map(({
          component,
          top = 'auto',
          left = 'auto',
          right = 'auto',
          bottom = 'auto',
          offsetY = '20%',
          offsetX = 0,
          reverseX,
          reverseY,
          style = {}
        }, index) => {
          return (
            <div
              key={index}
              className={styles.child}
              style={{
                top: top,
                left: left,
                right: right,
                bottom: bottom,
                ...style
              }}
            >
              <Parallax
                offsetYMax={offsetY}
                offsetYMin={'-' + offsetY}
                offsetXMax={reverseX ? '-' + offsetX : offsetX}
                offsetXMin={reverseX ? offsetX : '-' + offsetX}
                slowerScrollRate={reverseY}
              >
                {component}
              </Parallax>
            </div>
          )
        })}
        {children}
      </div>
    )
  }
}

BackgroundWrapper.contextTypes = {
  parallaxController: PropTypes.object.isRequired
}

export default BackgroundWrapper
