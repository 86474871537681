import React, { Component } from 'react'
import DeviceSliderClass from '../../misc/deviceSlider/deviceSlider'
import '../../misc/deviceSlider/_device-slider.scss'

class DeviceSlider extends Component {
  componentDidMount () {
    const { slides, initialItemMargin, framesPerSecond, itemSpeedMax } = this.props
    this.deviceSlider = new DeviceSliderClass(this.sliderContainer, {
      slides,
      initialItemMargin,
      framesPerSecond,
      itemSpeedMax
    })
    this.deviceSlider.init()
  }

  componentWillUnmount () {
    this.deviceSlider.destroy()
  }

  render () {
    return (
      <div className='cmp-device-slider' ref={ref => { this.sliderContainer = ref }} />
    )
  }
}

DeviceSlider.defaultProps = {
  slides: [],
  framesPerSecond: 60,
  initialItemMargin: 25,
  itemSpeedMax: 5,
  initialSpeed: 2,
  sizeSpeedThrottle: {
    '2': 0.7,
    '1': 0.85,
    '0': 1
  },
  onUpdate: false
}

export default DeviceSlider
