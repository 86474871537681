import * as THREE from 'three'

// options
const COLOR_BLUE = '#65627A'
const COLOR_RED = '#F2756B'
const COLOR_YELLOW = '#FFD369'
const LIGHT_ROOM = 5
const LIGHT_FRONT = 1.5

// lighting
export const roomLight = new THREE.AmbientLight(0x454545, LIGHT_ROOM)
export const directionalLight = new THREE.DirectionalLight(0xffffff, LIGHT_FRONT)

// torus
export const torusLeft = {
  mesh: new THREE.Mesh(
    new THREE.TorusBufferGeometry(10, 3.5, 32, 100),
    new THREE.MeshStandardMaterial({
      color: COLOR_BLUE,
      roughness: 15
    })
  ),
  position: {
    x: -20,
    y: 20,
    z: 50
  },
  rotation: {
    x: -0.002,
    y: -0.003,
    z: 0.001
  }
}

// sphere
export const sphere = {
  mesh: new THREE.Mesh(
    new THREE.SphereBufferGeometry(12, 32, 32),
    new THREE.MeshStandardMaterial({
      color: COLOR_BLUE,
      roughness: 15
    })
  ),
  position: {
    x: 50,
    z: -25
  },
  rotation: {
    x: 0.001,
    y: 0.001,
    z: -0.002
  }
}

// octahedron
export const octaHedron = {
  mesh: new THREE.Mesh(
    new THREE.OctahedronBufferGeometry(12),
    new THREE.MeshStandardMaterial({
      color: COLOR_YELLOW,
      roughness: 15
    })
  ),
  position: {
    x: 20,
    y: 20,
    z: 75
  },
  rotation: {
    x: -0.0008,
    y: -0.0035,
    z: 0.002
  }
}

// cube
export const cube = {
  mesh: new THREE.Mesh(
    new THREE.BoxBufferGeometry(30, 30, 30),
    new THREE.MeshStandardMaterial({
      color: COLOR_RED,
      roughness: 15
    })
  ),
  position: {
    z: -50
  },
  rotation: {
    x: 0.002,
    y: -0.001,
    z: 0.003
  },
  rotationSpeed: 1
}

// cylinder
export const cylinder = {
  mesh: new THREE.Mesh(
    new THREE.CylinderBufferGeometry(4, 4, 16, 32),
    new THREE.MeshStandardMaterial({
      color: COLOR_YELLOW,
      roughness: 15
    })
  ),
  position: {
    x: 65,
    y: -25
  },
  rotation: {
    x: -0.002,
    y: 0.001,
    z: 0.001
  },
  rotationSpeed: 1
}

// cone
export const cone = {
  mesh: new THREE.Mesh(
    new THREE.ConeBufferGeometry(10, 25, 32),
    new THREE.MeshStandardMaterial({
      color: COLOR_RED,
      roughness: 15
    })
  ),
  position: {
    x: 85,
    z: -75
  },
  rotation: {
    x: 0.001,
    y: 0.0015,
    z: -0.002
  }
}

// torus
export const torusRight = {
  mesh: new THREE.Mesh(
    new THREE.TorusBufferGeometry(8, 2.8, 32, 100),
    new THREE.MeshStandardMaterial({
      color: COLOR_BLUE,
      roughness: 15
    })
  ),
  position: {
    x: 50,
    y: 30,
    z: 50
  },
  rotation: {
    x: 0.0008,
    y: -0.0025,
    z: 0.0005
  }
}
