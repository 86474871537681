import React from 'react'
import Hypher from 'hypher'
import { is } from '../../misc/hypen-patterns'

const h = new Hypher(is)

const Hyphen = ({ component: Component, children, ...props }) => {
  const text = children.split(' ').reduce((text, word) => {
    const hyphenedWord = h.hyphenate(word).join('&shy;')
    text += ' ' + hyphenedWord
    return text
  }, '')
  if (Component) {
    return <Component dangerouslySetInnerHTML={{ __html: text }} {...props} />
  } else {
    return <p dangerouslySetInnerHTML={{ __html: text }} {...props} />
  }
}

export default Hyphen
