import React from 'react'
import styles from './Comment.module.scss'
import PropTypes from 'prop-types'

const Comment = ({ children, name, title, image, ...props }) => {
  return (
    <div className={styles.container}>
      <div className={styles.userInfo}>
        <div
          className={styles.userImage} style={{
            backgroundImage: `url(${image.src})`
          }} />
        <div>
          <div className={styles.userName}>{name}</div>
          <div className={styles.userTitle}>{title}</div>
        </div>
      </div>
      <div className={styles.textBox}>
        {children}
      </div>
    </div>
  )
}

Comment.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string
}

Comment.default = {
  name: '',
  title: '',
  src: ''
}

export default Comment
