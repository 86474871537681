import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import Helmet from 'react-helmet'
import { debounce } from 'lodash'
import classnames from 'classnames'
import ReactResizeDetector from 'react-resize-detector'
import styles from './Main.module.scss'
import { ContextProviderComponent, ConsumerWrapper, LanguageStrings } from '../../Context'
import { ThirdDimension, Navigation, Footer, Logo, WindowResize } from '../../components'
import fancyCursor from '../../misc/fancy-cursor'
import { WebpIsSupported } from '../../misc/utilities'

class Main extends React.Component {
  componentDidMount () {
    fancyCursor()
  }

  render () {
    const {
      children
    } = this.props

    const texts = this.context || {}

    return (
      <ParallaxProvider>
        <Helmet>
          <title>Kosmos & Kaos</title>
          <meta name='description' content={texts.helmet.description} />
          <meta charset='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta property='og:type' content='Kosmos & Kaos' />
          <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
          <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
          <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
          <link rel='icon' type='image/gif' href='/kk-fav.gif' />
          <link rel='manifest' href='/site.webmanifest' />
          <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
          <meta name='msapplication-TileColor' content='#da532c' />
          <meta name='theme-color' content='#000000' />
          <html lang='is' />
        </Helmet>
        <ContextProviderComponent>
          <Navigation />
          <Logo />
          <ContentContainer>
            <ThirdDimensionContext />
            {children}
          </ContentContainer>
          <Footer />
          <WindowResize />
          <div className='fancy-cursor'>
            <div className='fancy-cursor__circle' />
          </div>
        </ContextProviderComponent>
      </ParallaxProvider>
    )
  }
}

const ContentContainer = ConsumerWrapper(({ consumerContext: { bgColor = '#F2F2F5', footerHeight, windowHeight, navigationTransparent }, children }) => (
  <div
    className={styles.contentContainer}
    style={{
      marginBottom: windowHeight > footerHeight ? footerHeight : 0
    }}
  >
    <div className={styles.outerBackground} />
    <div
      className={styles.innerBackground}
      style={{
        backgroundColor: bgColor
      }}
    />
    <div className={classnames(styles.content, {
      [styles.navigationPadding]: !navigationTransparent
    })}>
      {children}
    </div>
    <CalculateContentHeight />
    <CheckWebPSupport />
  </div>
), ['bgColor', 'footerHeight', 'windowHeight', 'navigationTransparent'])

const ThirdDimensionContext = ConsumerWrapper(({ consumerContext }) => {
  return (
    <ThirdDimension active={consumerContext.thirdDimensionActive} />
  )
}, ['thirdDimensionActive'])

const CheckWebPSupport = ConsumerWrapper(class extends React.Component {
  componentDidMount () {
    const { consumerContext } = this.props

    WebpIsSupported(isSupported => {
      if (!isSupported) {
        consumerContext.set({
          webPSupported: false
        })
      }
    })
  }
  render () {
    return null
  }
}, ['set'])

const CalculateContentHeight = ConsumerWrapper(class extends React.Component {
  constructor () {
    super()
    this.onResize = this.onResize.bind(this)
  }
  onResize (w, h) {
    const { consumerContext } = this.props
    const stopOffset = 56

    consumerContext.set({
      logoStopValue: h - stopOffset
    })
  }
  render () {
    return <ReactResizeDetector handleHeight onResize={debounce(this.onResize, 300)} />
  }
}, ['set'])

Main.contextType = LanguageStrings

export default Main
