import { Component } from 'react'
import { debounce } from 'lodash'
import { ConsumerWrapper } from '../../Context'

class WindowHeight extends Component {
  constructor (props) {
    super(props)
    this.onResize = this.onResize.bind(this)
  }

  componentDidMount () {
    window.addEventListener('resize', debounce(this.onResize, 400))
    this.onResize()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', debounce(this.onResize, 400))
  }

  onResize (e) {
    if (window) {
      this.props.consumerContext.set({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      })
    }
  }

  render () {
    return null
  }
}

export default ConsumerWrapper(WindowHeight, ['set'])
