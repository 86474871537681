import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './DotGrid.module.scss'
import plusDot from '../../images/plus-grid-2.png'
import dotDot from '../../images/dot-grid-2.png'

const DotGrid = ({ type, volumeX, volumeY, className }) => {
  const gridBackground = type => {
    switch (type) {
      case 'plus': {
        return plusDot
      }
      case 'dot': {
        return dotDot
      }
      default: {
        return ''
      }
    }
  }
  const dotSize = 36
  return (
    <div
      className={classnames(styles.container, className)}
      style={{
        backgroundImage: `url(${gridBackground(type)})`,
        width: dotSize * volumeX,
        height: dotSize * volumeY,
        opacity: 0.7
      }}
    />
  )
}

DotGrid.propTypes = {
  type: PropTypes.oneOf(['dot', 'plus']),
  volumeX: PropTypes.number,
  volumeY: PropTypes.number
}

DotGrid.defaultProps = {
  type: 'dot',
  volumeX: 12,
  volumeY: 12
}

export default DotGrid
