import is from 'date-fns/locale/is'
import format from 'date-fns/format'
import fetch from 'isomorphic-fetch'

const urlify = require('urlify').create({
  spaces: '-',
  nonPrintable: '-',
  trim: true,
  toLower: true
})

export const slugify = string => {
  return urlify(string)
}

export const getImageObj = (imageObj, size = 'medium') => {
  return {
    width: imageObj[size + '-width'],
    height: imageObj[size + '-height'],
    src: imageObj[size]
  }
}

export const hexToRGBA = (color, alpha = 1) => {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
  if (!rgb) { return null }
  const rgba = {
    r: parseInt(rgb[1], 16),
    g: parseInt(rgb[2], 16),
    b: parseInt(rgb[3], 16),
    a: alpha
  }
  return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
}

export const shadeColor = (color, percent) => {
  const R = parseInt(color.substring(1, 3), 16)
  const G = parseInt(color.substring(3, 5), 16)
  const B = parseInt(color.substring(5, 7), 16)

  const shadeR = Math.min(parseInt(R * (100 + percent) / 100), 255)
  const shadeG = Math.min(parseInt(G * (100 + percent) / 100), 255)
  const shadeB = Math.min(parseInt(B * (100 + percent) / 100), 255)

  const RR = ('00' + shadeR.toString(16)).slice(-2)
  const GG = ('00' + shadeG.toString(16)).slice(-2)
  const BB = ('00' + shadeB.toString(16)).slice(-2)

  return `#${RR}${GG}${BB}`
}

export const formatDate = date => {
  if (!date) {
    return null
  }

  const formatStr = 'D. MMMM YYYY'

  return format(date, formatStr, { locale: is })
}

export const WebpIsSupported = callback => {
  let value = false
  // If the browser doesn't has the method createImageBitmap, you can't display webp format
  if (!window.createImageBitmap) {
    callback(value)
    return
  }

  const isIEorEdge = () => {
    const ua = window.navigator.userAgent

    const msie = ua.indexOf('MSIE ')
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
    }

    const trident = ua.indexOf('Trident/')
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:')
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
    }

    const edge = ua.indexOf('Edge/')
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
    }

    return false
  }

  const browserDoesNotSupport = isIEorEdge()
  if (browserDoesNotSupport) {
    callback(value)
    return
  }

  // Base64 representation of a white point image
  const webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA='

  // Retrieve the Image in Blob Format
  fetch(webpdata).then(response => {
    return response.blob()
  }).then(blob => {
    // If the createImageBitmap method succeeds, return true, otherwise false
    window.createImageBitmap(blob).then(() => {
      value = true
      callback(value)
    }, () => {
      callback(value)
    })
  })
}

export const getJobDescription = (desc, lang) => {
  let jobDescription = desc

  if (lang === 'en') {
    switch (desc) {
      case 'Hönnuður':
        jobDescription = 'Designer'
        break
      case 'Forritari':
        jobDescription = 'Programmer'
        break
      case 'Forritunarstjóri':
        jobDescription = 'Lead developer'
        break
      case 'Framkvæmdarstjóri':
        jobDescription = 'CEO'
        break
      case 'Svæðisstjóri':
        jobDescription = 'Country manager'
        break
      case 'Eigandi / Hönnunarstjóri':
        jobDescription = 'Founder / Creative Director'
        break
    }
  }

  return jobDescription
}
