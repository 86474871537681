import React from 'react'
import classnames from 'classnames'
import styles from './BlogCard.module.scss'
import { Hyphen, Link, AuthorTag, Image } from '../../components'

const BlogCard = ({ title, image, author, date, href }) => {
  return (
    <Link to={href}>
      <div>
        <div className={classnames(styles.imageContainer)}>
          <Image
            className={styles.image}
            image={image}
            alt={title}
            ratio={0.620183}
          />
        </div>
        <h3 className='pr-2'><Hyphen>{title}</Hyphen></h3>
        <AuthorTag
          avatar={author.avatar_urls.wordpress_96}
          name={author.name}
          date={date}
        />
      </div>
    </Link>
  )
}

export default BlogCard
