/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import dribbble from '../../images/icons/dribbble.svg'
import instagram from '../../images/icons/instagram.svg'
import facebook from '../../images/icons/facebook.svg'
import twitter from '../../images/icons/twitter.svg'
import spotify from '../../images/icons/spotify.svg'
import linkedin from '../../images/icons/linkedin.svg'

const icons = {
  instagram,
  dribbble,
  facebook,
  twitter,
  linkedin,
  spotify
}

const SocialMediaIcons = props => (
  <img src={icons[props.icon]} alt='/' />
)

SocialMediaIcons.propTypes = {
  icon: PropTypes.string.isRequired
}

export default SocialMediaIcons
