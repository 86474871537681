const fancyCursor = () => {
  let mouseX = 0
  let mouseY = 0
  let _event = null
  let timer = null

  const follower = document.querySelector('.fancy-cursor')

  function positionElement () {
    follower.style.top = mouseY + 'px'
    follower.style.left = mouseX + 'px'

    const el = _event && _event.target.closest('a, button')

    if (el) {
      !follower.classList.contains('hovering') && follower.classList.add('hovering')
    } else {
      follower.classList.contains('hovering') && follower.classList.remove('hovering')
    }
  }

  window.onmousemove = event => {
    !follower.classList.contains('outer-visible') && follower.classList.add('outer-visible')

    clearTimeout(timer)

    timer = setTimeout(() => {
      follower.classList.contains('outer-visible') && follower.classList.remove('outer-visible')
    }, 3000)

    _event = event
    mouseX = event.clientX + 2
    mouseY = event.clientY + 2
  }

  function animate () {
    positionElement()
    window.requestAnimationFrame(animate)
  }

  animate()
}

export default fancyCursor
