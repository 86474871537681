// Translation objects are devided into pages for easier navigation

export default {
  // Icelandic translation starts here
  'is': {
    lang: 'is',
    navbar: {
      aboutUs: 'Hver erum við',
      service: 'Hvað gerum við',
      projects: 'Verkefni',
      blog: 'Blogg'
    },
    aboutUs: {
      main_header: 'Þar sem hönnun og kóði gamna sér saman',
      under_header: 'og úr verður stafræn upplifun sem tekið er eftir.',
      context_header: 'Hönnun er hjartans mál',
      context: `<p>
           Í upphafi var Kaos, formlausa tómarúmið hvaðan öll sköpun kom,
           og Kaos gat Kosmos: alheiminn í formi.
         </p>
         <p>
           Kosmos & Kaos er stafrænt þenkjandi hönnunarstofa sem ræðst í öll verkefni með hjartað að vopni.
           Með glöggu auga fyrir hönnun, góðu handverki og markvissri miðlun höfum við um árabil gætt vefsíður og markaðsefni viðskiptavina okkar lífi og hlotið lof fyrir.
         </p>
         <p>
           Stofan var stofnuð í mars árið 2010 í látlausum höfuðstöðvum ríkisskattstjóra í Reykjavík.
           Á skömmum tíma fór hún úr því að sinna verkefnum við eldhúsborðið yfir í að opna tvö útibú;
           eitt í Reykjanesbæ og annað í Reykjavík.
           Auk viðurkenninga og verðlauna fyrir vel unnin störf hefur Kosmos & Kaos fengið viðurkenningu
           fyrir að vera fjölskylduvænt fyrirtæki og vakið athygli fyrir öfluga starfsmannastefnu sem sífellt er í mótun.
           </p>`,
      staff: 'Starfsfólk',
      apply: 'Viltu sækja um?',
      apply_mail: 'job@kosmosogkaos.is',
      clients: 'Viðskiptavinir',
      clients_text: `<p>
           Við hjálpum viðskiptavinum okkar að skara fram úr.
           Ekki með  flöffi og flóknum orðum,
           heldur með því að búa til framúrskarandi stafræna upplifun fyrir notendur þeirra.
           </p>`,
      awards: 'Verðlaun & viðurkenningar',
      awards_context: 'Verk okkar hafa unnið til fjölmargra verðlauna bæði hér heima og erlendis.'

    },
    buttons: {
      get_more: 'Sækja fleiri færslur',
      all_projects: 'Sjá öll verkefni',
      in_detail: 'Skoða nánar',
      see_more: 'Skoða meira stöff'
    },
    footer: {
      footer_title: 'Tölum saman.',
      tel: '+354 561 1020',
      mail: 'samskipti@kosmosogkaos.is',
      rvk_office: {
        title: 'Reykjavík',
        contact: 'Inga Birna Ragnarsdóttir',
        phone: '+354 561 1020',
        email: 'inga@kosmosogkaos.is',
        address: 'Hólmaslóð 4, 101 Reykjavík'
      },
      de_office: {
        title: 'Stockholm',
        contact: 'Ingi Már Elvarsson',
        phone: '+46(0)733444567',
        email: 'ingimar@kosmosochkaos.se',
        address: 'Regeringsgatan 29',
        address_2: 'c/o WeWork - 112 51 Stockholm'
      },
      language_link: {
        url: 'http://www.kosmosandkaos.com',
        title: 'English'
      }
    },
    home: {
      main_header: 'Stafrænt vel gert',
      under_header: 'Okkar helsta áhugamál er vandlega hönnuð notendaupplifun.',
      welcome: '',
      blog_container_title: 'Kosmosið talar',
      to_service: 'Nánar um þjónustu',
      to_blogs: 'Sjá eldra efni'
    },
    projects: 'Verkefni',
    dribble_title: 'Ferskt á Dribbble',
    blog: 'Blogg',
    new_on_instagram: 'Nýtt á Instagram',
    scroll: '------------  SKRUNAÐU NIÐUR',
    service: {
      service_header_main: 'Við setjum stafræn vistkerfi í loftið',
      service_header_under: 'og hjálpum fyrirtækjum að veita framúrskarandi þjónustu.',
      time_title: 'Tíminn líður hratt',
      time_text: '<p>Viðskiptaumhverfi fyrirtækja er að breytast. Ásýnd þeirra og þjónusta í stafrænum miðlum hefur sífellt meiri áhrif á samkeppnishæfni þeirra og stöðu á markaði.</p><p>Fremstu fyrirtæki morgundagsins verða þau sem hugsa stafrænt.</p>',
      service_title: 'Þjónustan',
      consultancy_title: 'Ráðgjöf',
      consultancy_text: 'Samanlögð þekking okkar á stafrænni tækni og vísindum er okkar helsta og verðmætasta söluvara.',
      strategies_title: 'Stafræn mörkun',
      strategies_text: 'Sýn fólks á fyrirtæki markast í sífellt meira mæli af því hvernig þau birtast í stafrænum miðlum.',
      design_title: 'Hönnun',
      design_text: 'Góð hönnun skilar sér í ánægjulegri upplifun notenda og borgar sig alltaf.',
      programming_title: 'Forritun',
      programming_text: 'Að vanda til verka frá upphafi þegar kemur að forritun er góð fjárfesting og eykur lífsgæði á hinu stafræna tilvistarsviði.',
      UX_title: 'Við hönnum notendaupplifun',
      UX_text: 'fyrir vefi, snjallforrit og allt þar sem skjár og manneskja mætast.',
      results_title: 'Afraksturinn',
      results_text: 'eru verkefni sem skila ánægjulegri upplifun notenda.',
      arion_title: 'Arionbanki.is',
      arion_text: 'Mörkun, vefhönnun, hönnunarkerfi & framendaforritun'
    },
    helmet: {
      description: 'Stafrænt vel gert. Okkar helsta áhugamál er vandlega hönnuð notendaupplifun.'
    }
  },
  // English translation starts here
  'en': {
    lang: 'en',
    navbar: {
      aboutUs: 'About us',
      service: 'Our services',
      projects: 'Projects',
      blog: 'Blog'
    },
    aboutUs: {
      main_header: 'Where design and technology come together',
      under_header: 'to create a wonderful digital experience.',
      context_header: 'Kosmos & Kaos is a full-service digital agency that believes in the bottom-line value of design.',
      context: '<p>The company was founded in March of 2010 in the unassuming headquarters of the Internal Revenue in Reykjavík. We quickly went from doing business at the kitchen table to opening two branch offices, one in Reykjanesbær and another in Reykjavík. In addition to the recognition and awards we have received for our work, Kosmos & Kaos has attracted attention for its progressive HR policies and for being a family-friendly workplace.</p>',
      staff: 'The team',
      apply: 'Want to join in?',
      apply_mail: 'job@kosmosogkaos.is',
      clients: 'Clients',
      clients_text: '<p>We work with our clients to reach their business goals through digital user experience.</p>',
      awards: 'Awards & recognition',
      awards_context: 'It’s nice to get an award for your work and we have a growing collection.'
    },
    buttons: {
      get_more: 'View more',
      all_projects: 'See all projects',
      in_detail: 'See in detail',
      see_more: 'See more stuff'
    },
    footer: {
      footer_title: 'Let’s talk.',
      footer_text: '',
      tel: '+354 561 1020',
      mail: 'samskipti@kosmosogkaos.is',
      rvk_office: {
        title: 'Reykjavík',
        contact: 'Inga Birna Ragnarsdóttir',
        phone: '+354 561 1020',
        email: 'inga@kosmosogkaos.is',
        address: 'Hólmaslóð 4, 101 Reykjavík'
      },
      de_office: {
        title: 'Stockholm',
        contact: 'Ingi Már Elvarsson',
        phone: '+46(0)733444567',
        email: 'ingimar@kosmosochkaos.se',
        address: 'Regeringsgatan 29',
        address_2: 'c/o WeWork - 112 51 Stockholm'
      },
      language_link: {
        url: 'http://www.kosmosogkaos.is',
        title: 'Íslenska'
      }
    },
    home: {
      main_header: 'Bottom-line digital',
      under_header: 'A beautifully designed user experience is damn good business.',
      welcome: '',
      blog_container_title: 'The Kosmos speaks',
      to_service: 'More about our services',
      to_blogs: 'Read more stuff'
    },
    projects: 'Projects',
    dribble_title: 'Fresh on Dribbble',
    blog: 'Blog',
    new_on_instagram: 'New on Instagram',
    scroll: '------------  SCROLL DOWN',
    service: {
      service_header_main: 'We build digital products and services',
      service_header_under: 'that make it easier for our clients to meet their business goals.',
      time_text: '<p><h2>It’s a new world</h2></p><p>The landscape of today’s businesses is changing and a company’s digital presence is an increasingly significant success factor.</p><p>Tomorrow’s leading companies will be those that shine the brightest in the digital realm.</p>',
      service_title: 'Our services',
      consultancy_title: 'Consulting',
      consultancy_text: 'Our combined experience and knowledge of all things digital is our most valuable asset.',
      strategies_title: 'Digital branding',
      strategies_text: 'Increasingly, the image people have of your company is shaped by its presence in digital media.',
      design_title: 'Design',
      design_text: 'Thoughtful design leads to a positive user experience and happiness for everyone involved.',
      programming_title: 'Development',
      programming_text: 'Build your digital house on a solid foundation and nothing will shake your confidence.',
      UX_title: 'We design user experiences',
      UX_text: 'for websites, mobile apps and everything involving humans interacting with screens.',
      results_title: 'The results',
      results_text: 'speak for themselves.',
      arion_title: 'Arionbanki.is',
      arion_text: 'Digital branding, webdesign, design systems & front-end programming'
    },
    helmet: {
      description: 'Bottom-line digital. A beautifully designed user experience is damn good business.'
    }
  }
}
