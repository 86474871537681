import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Card.module.scss'
import { isEmpty } from 'lodash'
import { Image, Link } from '../'

const Card = ({
  title,
  subtitle,
  jobDescription,
  image,
  className,
  bordered,
  ratio,
  theme,
  children,
  linkTo,
  linkClassName,
  useNormalImg
}) => {
  const CardComponent = () => (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.inner, 'mb-2',
        { [styles.bordered]: bordered },
        { [styles.blueTheme]: theme === 'blue' },
        { [styles.yellowTheme]: theme === 'yellow' })}
      >

        {image && !isEmpty(image) && !useNormalImg &&
          <Image
            image={image}
            ratio={ratio}
            alt={title}
          />
        }

        {image && image.src && useNormalImg &&
          <img
            src={image.src}
            alt={title}
          />
        }

        {children}

        {subtitle &&
          <p className='subtitle-description font-weight-normal pt-3 pt-md-6 pl-3 pr-3'>
            {subtitle}
          </p>
        }
      </div>

      {title &&
        <h2 className='headline-component mb-0'>
          {title}
        </h2>
      }

      {jobDescription &&
        <p className={classNames('headline-description', styles.jobDescription)}>
          {jobDescription}
        </p>
      }
    </div>
  )

  if (linkTo) {
    return <Link className={classNames(styles.link, linkClassName)} to={linkTo}><CardComponent /></Link>
  } else {
    return <CardComponent />
  }
}

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string
  })
}

Card.defaultProps = {
  title: '',
  subtitle: '',
  image: {},
  ratio: 1.3322981366
}

export default Card
