// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-page-templates-projects-projects-connected-js": () => import("/opt/build/repo/src/page-templates/Projects/Projects.connected.js" /* webpackChunkName: "component---src-page-templates-projects-projects-connected-js" */),
  "component---src-page-templates-home-home-connected-js": () => import("/opt/build/repo/src/page-templates/Home/Home.connected.js" /* webpackChunkName: "component---src-page-templates-home-home-connected-js" */),
  "component---src-page-templates-blog-article-blog-article-js": () => import("/opt/build/repo/src/page-templates/BlogArticle/BlogArticle.js" /* webpackChunkName: "component---src-page-templates-blog-article-blog-article-js" */),
  "component---src-page-templates-project-project-js": () => import("/opt/build/repo/src/page-templates/Project/Project.js" /* webpackChunkName: "component---src-page-templates-project-project-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-hafa-samband-js": () => import("/opt/build/repo/src/pages/hafa-samband.js" /* webpackChunkName: "component---src-pages-hafa-samband-js" */),
  "component---src-pages-preview-js": () => import("/opt/build/repo/src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-thjonusta-js": () => import("/opt/build/repo/src/pages/thjonusta.js" /* webpackChunkName: "component---src-pages-thjonusta-js" */),
  "component---src-pages-um-okkur-js": () => import("/opt/build/repo/src/pages/um-okkur.js" /* webpackChunkName: "component---src-pages-um-okkur-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

