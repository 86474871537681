import React, { Component } from 'react'
import styles from './ScrollIndicator.module.scss'
import classnames from 'classnames'
import { withLanguage } from '../../Context'

class ScrollIndicator extends Component {
  constructor () {
    super()

    this.state = {
      display: true
    }

    this.onScroll = this.onScroll.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll () {
    this.setState({
      display: window.scrollY <= 150
    })
  }

  render () {
    const { display } = this.state
    const texts = this.props.language
    return (
      <div className={classnames(styles.container, { [styles.isActive]: display })}>
        <div className={styles.innerContainer}>
          <p className={styles.p}>{ texts.scroll }</p>
        </div>
      </div >
    )
  }
}

export default withLanguage(ScrollIndicator)
