import React from 'react'
import styles from './LogoDisplay.module.scss'
import classnames from 'classnames'
import { Link } from '../../components'

const LogoDisplay = ({ image, title, ratio, link, className, isBlue }) => {
  return (
    <Link
      to={link}
      className={classnames(styles.container, className, { [styles.blueContainer]: isBlue })}
    >
      <div
        className={styles.innerContainer}
        style={{ paddingTop: `${ratio * 100}%` }}
      >
        <img
          src={image}
          alt={title}
          className={styles.image}
        />
      </div>
    </Link>
  )
}

export default LogoDisplay
