import React from 'react'
import Link from '../Link'
import PropTypes from 'prop-types'
import { Motion, spring } from 'react-motion'
import { sample } from 'lodash'
import classnames from 'classnames'
import styles from './Navigation.module.scss'
import { ConsumerWrapper, withLanguage } from '../../Context'
import homeMan from '../../images/home-icon/home-man.svg'
import homeCosmonaut from '../../images/home-icon/home-cosmonaut.svg'
import homeHouse from '../../images/home-icon/home-house.svg'
import homeZen from '../../images/home-icon/home-zen.svg'

const homeImg = sample([
  homeMan,
  homeCosmonaut,
  homeHouse,
  homeZen
])

const LogoSVG = ({ className }) => (
  <svg className={className} version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 510.8 116'>
    <polyline points='21.8,94.3 14.5,60.1 14.5,94.3 0,94.3 0,21.9 14.5,21.9 14.5,55.9 22.6,21.9 37.6,21.9 28.1,57.5 37.2,94.3 21.8,94.3 ' />
    <path d='M64.1,94.9h-9.3c-9,0-13.5-4.5-13.5-13.5V34.7c0-9,4.5-13.5,13.5-13.5h9.3c9,0,13.5,4.5,13.5,13.5v46.8 C77.5,90.5,73.1,94.9,64.1,94.9 M60.5,33.7h-2.1c-1.8,0-2.6,0.8-2.6,2.6v43.7c0,1.8,0.8,2.6,2.6,2.6h2.1c1.8,0,2.6-0.8,2.6-2.6V36.2 C63,34.5,62.2,33.7,60.5,33.7z' />
    <path d='M119.2,68.1v13.4c0,9-4.5,13.5-13.5,13.5h-8.1c-9,0-13.5-4.5-13.5-13.5V66.1h14.2v14.1c0,1.8,0.8,2.6,2.6,2.6 h1.3c1.8,0,2.6-0.8,2.6-2.6v-8.7c0-2.3-0.7-3.4-2.5-4.7L90,57.6c-4.5-3.2-5.8-6.2-5.8-11.4V34.7c0-9,4.5-13.5,13.5-13.5h7.9 c9,0,13.5,4.5,13.5,13.5v13.2h-14.2V36c0-1.8-0.8-2.6-2.6-2.6H101c-1.8,0-2.6,0.8-2.6,2.6v6.8c0,2.3,0.7,3.4,2.5,4.7l12.4,9.2 C117.8,60,119.2,62.8,119.2,68.1' />
    <polyline points='150.7,65.7 155.1,21.9 176.1,21.9 176.1,94.3 161.8,94.3 161.8,46.8 156.3,94.3 142.7,94.3 137.2,46.3 137.2,94.3 125.6,94.3 125.6,21.9 146.5,21.9 150.7,65.7 ' />
    <path d='M205.9,94.9h-9.3c-9,0-13.5-4.5-13.5-13.5V34.7c0-9,4.5-13.5,13.5-13.5h9.3c9,0,13.5,4.5,13.5,13.5v46.8 C219.4,90.5,214.9,94.9,205.9,94.9 M202.3,33.7h-2.1c-1.8,0-2.6,0.8-2.6,2.6v43.7c0,1.8,0.8,2.6,2.6,2.6h2.1c1.8,0,2.6-0.8,2.6-2.6 V36.2C204.9,34.5,204,33.7,202.3,33.7z' />
    <path d='M261,68.1v13.4c0,9-4.5,13.5-13.5,13.5h-8.1c-9,0-13.5-4.5-13.5-13.5V66.1h14.2v14.1c0,1.8,0.8,2.6,2.6,2.6h1.3 c1.8,0,2.6-0.8,2.6-2.6v-8.7c0-2.3-0.7-3.4-2.5-4.7l-12.4-9.2c-4.5-3.2-5.8-6.2-5.8-11.4V34.7c0-9,4.5-13.5,13.5-13.5h7.9 c9,0,13.5,4.5,13.5,13.5v13.2h-14.2V36c0-1.8-0.8-2.6-2.6-2.6h-1.1c-1.8,0-2.6,0.8-2.6,2.6v6.8c0,2.3,0.7,3.4,2.5,4.7l12.4,9.2 C259.6,60,261,62.8,261,68.1' />
    <path d='M312,88.4c-2.3,4.5-5.3,6.5-11.4,6.5h-5.5c-8.4,0-12.6-4.2-12.6-12.6V67.7c0-5.1,1.1-9,4.9-12.2l4-3.5l-2.4-4.3 c-2.2-4-2.5-6.5-2.5-10.7v-2.3c0-9,4.5-13.5,13.5-13.5h6.2c9,0,13.5,4.5,13.5,13.5v9.6h-13.4v-8.2c0-1.8-0.8-2.6-2.6-2.6h-1 c-1.8,0-2.6,0.8-2.6,2.6v1.4c0,2.7,0.7,4.5,2.1,6.9l9,16.6l3.1-9.7h14.2l-8.6,23.9l10.4,19h-14.9L312,88.4 M303.3,80.7l1.6-3.9 l-6.9-12.6c-1.3,1.2-1.8,2.4-1.8,4.7v11.9c0,1.6,0.7,2.3,2.3,2.3h1.9C301.8,82.9,302.7,82.3,303.3,80.7z' />
    <polyline points='488.8,21.7 496.1,56 496.1,21.7 510.6,21.7 510.6,94.2 496.1,94.2 496.1,60.1 488,94.2 473,94.2 482.5,58.5 473.4,21.7 488.8,21.7 ' />
    <path d='M432.6,21.7H447l1.1,10.6h9.1l1-10.6h13.1l-9.5,72.5h-19.8L432.6,21.7 M455.9,44.9h-6.5l3.3,31.7L455.9,44.9z' />
    <path d='M405.4,21.1h9.3c9,0,13.5,4.5,13.5,13.5v46.8c0,9-4.5,13.5-13.5,13.5h-9.3c-9,0-13.5-4.5-13.5-13.5V34.5 C392,25.5,396.4,21.1,405.4,21.1 M409,82.4h2.1c1.8,0,2.6-0.8,2.6-2.6V36.1c0-1.8-0.8-2.6-2.6-2.6H409c-1.8,0-2.6,0.8-2.6,2.6v43.7 C406.4,81.5,407.3,82.4,409,82.4z' />
    <path d='M350.3,47.9V34.5c0-9,4.5-13.5,13.5-13.5h8.1c9,0,13.5,4.5,13.5,13.5V50h-14.2V35.9c0-1.8-0.8-2.6-2.6-2.6h-1.3 c-1.8,0-2.6,0.8-2.6,2.6v8.7c0,2.3,0.7,3.4,2.5,4.7l12.4,9.2c4.5,3.2,5.8,6.2,5.8,11.4v11.5c0,9-4.5,13.5-13.5,13.5H364 c-9,0-13.5-4.5-13.5-13.5V68.1h14.2V80c0,1.8,0.8,2.6,2.6,2.6h1.1c1.8,0,2.6-0.8,2.6-2.6v-6.8c0-2.3-0.7-3.4-2.5-4.7l-12.4-9.2 C351.7,56.1,350.3,53.3,350.3,47.9' />
    <rect x='0.4' y='106' width='259.4' height='10' />
    <rect x='349.5' width='161.3' height='10' />
  </svg>
)

class Navigation extends React.Component {
  constructor (props) {
    super()

    this.breakpoint = 768

    this.motion = {
      damping: 12,
      stiffness: 100
    }

    const { service, aboutUs, projects, blog } = props.language.navbar

    this.state = {
      active: false,
      mobile: true,
      routes: [
        {
          path: '/thjonusta',
          title: service
        },
        {
          path: '/verkefnin',
          title: projects
        },
        {
          path: '/um-okkur',
          title: aboutUs
        }
      ]
    }

    if (props.language.lang === 'is') {
      this.state.routes = [
        ...this.state.routes,
        {
          path: '/blog',
          title: blog
        }]
    }

    this.onClick = this.onClick.bind(this)
    this.onClickItem = this.onClickItem.bind(this)
    this.setMobileState = this.setMobileState.bind(this)
  }

  componentDidMount () {
    window.addEventListener('resize', this.setMobileState)
    this.setMobileState()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setMobileState)
  }

  onClick (event) {
    const {
      onClick
    } = this.props

    const {
      active
    } = this.state

    if (onClick) {
      onClick(event, { active })
    }

    this.setState({
      active: !active
    })
  }

  onClickItem () {
    this.setState({
      active: false
    })
  }

  isMobile () {
    return window.innerWidth < this.breakpoint
  }

  setMobileState () {
    this.setState({
      mobile: this.isMobile()
    })
  }

  showNav () {
    const {
      active,
      mobile
    } = this.state

    return active || !mobile
  }

  showContent (opacity) {
    const {
      mobile
    } = this.state

    return opacity > 0 || !mobile
  }

  render () {
    const {
      consumerContext
    } = this.props
    const { mobile, routes } = this.state

    const { navigationTransparent } = consumerContext
    const showNav = this.showNav()

    return (
      <Motion style={{
        opacity: spring(showNav ? 1 : 0, this.motion),
        burgerTopRotate: spring(showNav ? -45 : 0, this.motion),
        burgerTopY: spring(showNav ? 6 : 0, this.motion),
        burgerBottomRotate: spring(showNav ? -135 : 0, this.motion),
        burgerBottomY: spring(showNav ? -6 : 0, this.motion),
        listItemMinY: spring(showNav ? 0 : -10, this.motion),
        iconItemMinY: spring(showNav ? -2 : -10, this.motion)
      }}>

        {motion =>
          <div className={classnames(styles.container, {
            [styles.transparent]: navigationTransparent,
            [styles.openNav]: showNav
          })}>
            <div className={styles.header}>
              <Link
                className={styles.logo}
                to='/'>
                <span className='sr-only'>Home</span>
                <LogoSVG className={styles.logo} />
              </Link>
              <div
                onClick={this.onClick}
                onKeyUp={this.onClick}
                role='button'
                tabIndex={0}
                className={styles.burgerContainer}>
                <span className='sr-only'>Open menu</span>
                <div className={styles.burger}>
                  <div
                    style={{
                      transform: `translateY(${motion.burgerTopY}px) rotate(${motion.burgerTopRotate}deg)`
                    }}
                    className={styles.burgerTop} />

                  <div
                    style={{
                      transform: `translateY(${motion.burgerBottomY}px) rotate(${motion.burgerBottomRotate}deg)`
                    }}
                    className={styles.burgerBottom} />
                </div>
              </div>
            </div>
            {this.showContent(motion.opacity) &&
              <div
                style={{
                  backgroundColor: `rgba(227, 224, 242, ${motion.opacity})`
                }}
                className={styles.content}>

                <ul className={styles.nav}>
                  {!mobile && (
                    <li
                      style={{
                        opacity: motion.opacity,
                        transform: `translateY(${motion.iconItemMinY}px)`
                      }}>
                      <Link
                        onClick={this.onClickItem}
                        onKeyUp={this.onClickItem}
                        role='button'
                        tabIndex={0}
                        to='/'>
                        <img src={homeImg} alt='home' />
                      </Link>
                    </li>
                  )}
                  {routes.map((route, index) => {
                    const offset = mobile ? 1 : 2

                    return (
                      <li
                        style={{
                          opacity: motion.opacity,
                          transform: `translateY(${motion.listItemMinY * (index + offset)}px)`
                        }}
                        key={index}>
                        <Link
                          onClick={this.onClickItem}
                          onKeyUp={this.onClickItem}
                          role='button'
                          tabIndex={0}
                          activeclassname={styles.activeLink}
                          to={route.path}>

                          {route.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
                <ul
                  style={{
                    opacity: motion.opacity
                  }}
                  className={styles.info}>
                  <li>
                    <a
                      onClick={this.onClickItem}
                      onKeyUp={this.onClickItem}
                      role='button'
                      tabIndex={0}
                      href='tel:+3545612010'>
                      +354 561 2010
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this.onClickItem}
                      onKeyUp={this.onClickItem}
                      role='button'
                      tabIndex={0}
                      href='mailto:info@kosmosogkaos.is'>
                      info@kosmosogkaos.is
                    </a>
                  </li>
                </ul>
              </div>
            }
          </div>
        }
      </Motion>
    )
  }
}

Navigation.propTypes = {
  onClick: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string
  }))
}

export default withLanguage(ConsumerWrapper(Navigation, ['navigationTransparent']))
