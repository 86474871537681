import React from 'react'
import { sample } from 'lodash'
import './src/styles/index.scss'
import Layout from './src/layouts'
import { ConsumerWrapper, LanguageStrings } from './src/Context'
import translations from './src/misc/translations'

export const wrapRootElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  const languageStrings = translations[process.env.GATSBY_LANGUAGE]
  return (
    <LanguageStrings.Provider value={languageStrings}>
      <Layout {...props}>
        {element}
      </Layout>
    </LanguageStrings.Provider>
  )
}

export const wrapPageElement = ({ element, props: { location } }) => {
  return (
    <div>
      <RouteChangeListener pathname={location.pathname} />
      { element}
    </div>
  )
}

const bgColors = [
  '#F2F2F5',
  '#E3E0F2',
  '#FFF3F1',
  '#FBE6E2',
  '#FBF8F2'
]

const RouteChangeListener = ConsumerWrapper(class extends React.Component {
  componentDidMount () {
    this.setThirdDimensionActive()
    this.setRandomBgColor()
  }

  setLanguage () {
    const { consumerContext: { set }, language } = this.props
    set({
      language
    })
  }

  componentDidUpdate (prevProps) {
    const { pathname } = this.props
    if (prevProps.pathname !== pathname) {
      this.setThirdDimensionActive()
      this.setRandomBgColor()
    }
  }

  setThirdDimensionActive () {
    const { consumerContext, pathname } = this.props
    const thirdDimensionWhiteList = ['', 'um-okkur', 'thjonusta', 'hafa-samband']
    consumerContext.set({
      thirdDimensionActive: thirdDimensionWhiteList.includes(pathname.replace(/\//g, ''))
    })
  }

  setRandomBgColor () {
    const { consumerContext, pathname } = this.props
    const bgColorWhiteList = ['', 'verkefnin', 'um-okkur', 'thjonusta', 'hafa-samband']
    if (bgColorWhiteList.includes(pathname.replace(/\//g, ''))) {
      consumerContext.set({
        bgColor: sample(bgColors)
      })
    }
  }

  render () {
    return null
  }
}, ['set'], {
  listen: ['pathname']
})
