import React from 'react'
import ReactResizeDetector from 'react-resize-detector'
import classnames from 'classnames'
import styles from './ProjectHero.module.scss'

const ProjectHero = ({ children, color, onResize, img }) => {
  return (
    <div className={styles.container} style={{ backgroundColor: color }}>
      {img &&
        <img src={img} alt='' className={styles.image} />
      }
      <div className={classnames('container', 'mb-5', styles.childrenContainer)}>
        {children}
      </div>
      <ReactResizeDetector handleHeight onResize={onResize} />
    </div>
  )
}

export default ProjectHero
