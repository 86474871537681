import React from 'react'
import * as THREE from 'three'
import { Motion, spring } from 'react-motion'
import styles from './ThirdDimension.module.scss'
import { roomLight, directionalLight, cube, cone, sphere, cylinder, torusLeft, torusRight, octaHedron } from './ThirdDimensionShapes'

// options
const FOV = 45
const NEAR = 1
const FAR = 1000
const CAMERA_DISTANCE = 400
const MOUSEMOVE_RATIO = 0.001
const MOUSEMOVE_MAX = 0.01

// for performance we update matrix and apply to vector
const cameraMatrix = new THREE.Matrix4()

// moving parts
const shapes = [cube, cone, sphere, cylinder, torusLeft, torusRight, octaHedron]

// window helper

const getWindowDimensions = () => ({ w: window.innerWidth, h: window.innerHeight * 2 })

// quaternion to calculate rotation based on vector axis
const getRotationQuaternion = (x, y, z) => {
  const quaternion = new THREE.Quaternion()

  quaternion.setFromAxisAngle(new THREE.Vector3(x, y, z), Math.PI / 2)

  return quaternion
}

class ThirdDimension extends React.Component {
  constructor () {
    super()

    this.animate = this.animate.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  componentDidMount () {
    // window dimensions
    const wd = getWindowDimensions()

    this.currentWindowHeight = window.innerHeight * 2
    this.currentWindowWidth = window.innerWidth

    // scene
    this.scene = new THREE.Scene()
    this.scene.add(roomLight)
    this.scene.add(directionalLight)

    // add moving parts to Scene
    for (let i = 0; i < shapes.length; i++) {
      this.scene.add(shapes[i].mesh)
    }

    // renderer
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true
    })

    // camera
    this.camera = new THREE.PerspectiveCamera(
      FOV,
      wd.w / wd.h,
      NEAR,
      FAR
    )

    // set props
    this.renderer.setSize(wd.w, wd.h)
    this.camera.lookAt(cube.mesh.position)
    this.camera.position.z = CAMERA_DISTANCE
    directionalLight.position.copy(this.camera.position)

    // position shapes
    this.positionElements()

    // add scene to DOM
    this.container.appendChild(this.renderer.domElement)

    // add event handlers
    document.addEventListener('mousemove', this.onMouseMove, { passive: true })
    window.addEventListener('resize', this.onResize)

    // requestAnimationFrame
    this.animate()
  }

  componentWillUnmount () {
    // remove event handlers, cancel animation frame
    document.removeEventListener('mousemove', this.onMouseMove)
    window.cancelAnimationFrame(this.animationFrame)
  }

  onMouseMove (e) {
    if (!this.container) return null

    // -0+ from center on X and Y axis based on MOUSEMOVE_RATIO
    const mouseX = (e.clientX * MOUSEMOVE_RATIO / this.container.clientWidth) - MOUSEMOVE_RATIO / 2
    const mouseY = (e.clientY * MOUSEMOVE_RATIO / (this.container.clientHeight / 2)) - MOUSEMOVE_RATIO / 2

    // set X MAX
    const moveX = Math.abs(mouseX) > MOUSEMOVE_MAX
      ? MOUSEMOVE_MAX
      : mouseX

    // set Y MAX
    const moveY = Math.abs(mouseY) > MOUSEMOVE_MAX
      ? MOUSEMOVE_MAX
      : mouseY

    // update cameraMatrix
    this.setCameraRotation(moveX, moveY)
  }

  setCameraRotation (x, y) {
    // quaternion
    const quaternion = getRotationQuaternion(y, x, 0)

    // camera rotation
    cameraMatrix.makeRotationFromQuaternion(quaternion)
  }

  onResize () {
    // set render size
    const windowHeight = window.innerHeight * 2
    const windowWidth = window.innerWidth

    // Max height difference in pixels after resize in order to trigger resize
    const maxDiff = 700

    // Only update if width has changed or height changed more than the allowed diff
    if (windowWidth !== this.currentWindowWidth ||
      windowHeight > (this.currentWindowHeight + maxDiff / 2) ||
      windowHeight < (this.currentWindowHeight - maxDiff / 2)) {
      this.renderer.setSize(windowWidth, windowHeight)
      this.positionElements()

      // update camera
      this.camera.aspect = windowWidth / windowHeight
      this.camera.updateProjectionMatrix()

      this.currentWindowHeight = windowHeight
      this.currentWindowWidth = windowWidth
    }
  }

  positionElements () {
    // window dimensions
    const wd = getWindowDimensions()
    const ratio = wd.h / wd.w

    // get scene width
    const sceneWidth = this.getSceneWidth()

    // for all moving parts
    for (let i = 0; i < shapes.length; i++) {
      const shape = shapes[i]

      // position each element based on config props
      shape.mesh.position.x = (shape.position && shape.position.x)
        ? sceneWidth * (shape.position.x / 100)
        : 0
      shape.mesh.position.y = (shape.position && shape.position.y)
        ? sceneWidth * ratio * (-shape.position.y / 100)
        : 0
      shape.mesh.position.z = (shape.position && shape.position.z) || 0
    }
  }

  getSceneWidth () {
    // get half of the cameras field of view angle in radians
    const fov = this.camera.fov / 180 * Math.PI / 2

    // get the adjacent to calculate the opposite
    // this assumes you are looking at the scene
    const adjacent = this.camera.position.distanceTo(this.scene.position)

    // Use trig to get the leftmost point (tangent = o / a)
    return Math.tan(fov) * adjacent * this.camera.aspect
  }

  rotateShapes () {
    // for all axis
    const axis = ['x', 'y', 'z']

    // do stuff on the axis
    for (let i = 0; i < shapes.length; i++) {
      const shape = shapes[i]

      // make rotation based on config props
      for (let j = 0; j < axis.length; j++) {
        shape.mesh.rotation[axis[j]] += shape.rotation[axis[j]] || 0
      }
    }
  }

  animate () {
    this.animationFrame = window.requestAnimationFrame(this.animate)
    // Create a generic rotation matrix that will rotate an object

    // rotate shapes based on config props rotation
    this.rotateShapes()

    // apply matrixes
    this.camera.position.applyMatrix4(cameraMatrix)

    // light position update
    directionalLight.position.copy(this.camera.position)
    directionalLight.position.x += 300
    directionalLight.position.y += 600

    // make camera look past the box
    this.camera.lookAt(new THREE.Vector3(
      cube.mesh.position.x - 25,
      cube.mesh.position.y + 25,
      cube.mesh.position.z + 25
    ))

    // render
    this.renderer.render(this.scene, this.camera)
  }

  render () {
    return (
      <Motion
        defaultStyle={{ opacity: 0 }}
        style={{
          opacity: spring(this.props.active ? 1 : 0, {
            damping: 10,
            stiffness: 80
          })
        }}
      >

        {motion =>
          <div
            className={styles.container}
            style={{
              opacity: motion.opacity
            }}
            ref={e => { this.container = e }} />
        }
      </Motion>
    )
  }
}

export default ThirdDimension
