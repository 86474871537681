import React from 'react'
import styles from './DribbblePreview.module.scss'
import { Link } from '../'
import classNames from 'classnames'

const DribbblePreview = ({ image, link, title, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Link
        to={link}
        className={styles.link}
      >
        <img
          src={image}
          alt={title}
          className={classNames(styles.image, 'mb-2')}
        />
        {title}
      </Link>
    </div>
  )
}

export default DribbblePreview
