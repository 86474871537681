import React from 'react'
import styles from './DeviceWrapper.module.scss'
import { Image } from '../'

const DeviceWrapper = ({ image, deviceType }) => {
  return (
    <div className={styles.container}>
      {deviceType === 'macbook' &&
        <div className={styles.macbook}>
          <div className={styles.device} />
          <div className={styles.screen}>
            <Image
              className={styles.img}
              image={image}
              incPadding={false}
            />
          </div>
        </div>
      }
      {deviceType === 'iphone' &&
        <div className={styles.iphone}>
          <div className={styles.device} />
          <div className={styles.screen}>
            <Image
              className={styles.img}
              image={image}
              incPadding={false}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default DeviceWrapper
