import React from 'react'
import classnames from 'classnames'
import styles from './Ratio.module.scss'

const Ratio = ({ className, children, ratio }) => {
  return (
    <div
      className={classnames(styles.container, className)}
      style={{ paddingTop: `${ratio * 100}%` }}
    >
      <div className={styles.innerContainer}>
        {children}
      </div>
    </div>
  )
}

export default Ratio
