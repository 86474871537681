import React, { Fragment } from 'react'
import classnames from 'classnames'
import ReactResizeDetector from 'react-resize-detector'
import { debounce } from 'lodash'
import styles from './Footer.module.scss'
import { Link, SocialMediaIcons as Icon } from '../'
import { ConsumerWrapper, LanguageStrings, withLanguage } from '../../Context'
import tdfooter from '../../images/td-footer.png'
import { BackgroundWrapper } from '../../components/'

class Footer extends React.Component {
  constructor () {
    super()

    this.onResize = this.onResize.bind(this)
  }

  onResize () {
    const { consumerContext } = this.props
    consumerContext.set({
      footerHeight: this.footer && this.footer.clientHeight
    })
  }

  render () {
    const texts = this.props.language
    const {
      className,
      consumerContext: {
        windowHeight,
        footerHeight
      }
    } = this.props

    const containerClass = classnames(
      styles.container,
      className, {
        [styles.fixed]: windowHeight > footerHeight
      }
    )
    return (
      <Fragment>
        <div
          ref={el => { this.footer = el }}
          className={containerClass}>

          <div className='container'>
            <BackgroundWrapper
              elements={[
                {
                  right: '15%',
                  style: {
                    transform: 'translateY(-30%)'
                  },
                  component: <img src={tdfooter} alt='tdfooter' style={{ width: '100%' }} />
                }
              ]}
            />
            <div className='row align-items-center justify-content-end'>
              <div className='col-lg-6'>
                <div className={styles.heading}>
                  <h3 className='mb-4 mb-lg-4' >{ texts.footer.footer_title} </h3>
                </div>
                <div className='row'>
                  <div className={classnames('col-12 col-sm-6 col-lg-5', styles.info)}>
                    <h3>{ texts.footer.rvk_office.title }</h3>
                    <p className='mb-0'>{ texts.footer.rvk_office.contact }</p>
                    <p className='mb-0'>
                      <Link to={`tel:${texts.footer.rvk_office.phone}`}>{ texts.footer.rvk_office.phone }</Link>
                    </p>
                    <p className='mb-0'>
                      <Link to={`mailto:${texts.footer.rvk_office.email}`}>{ texts.footer.rvk_office.email }</Link>
                    </p>
                    <p className='mb-0'>{ texts.footer.rvk_office.address }</p>
                  </div>
                  <div className={classnames('col-12 col-sm-6 col-lg-5', styles.info)}>
                    <h3>{ texts.footer.de_office.title }</h3>
                    <p className='mb-0'>{ texts.footer.de_office.contact }</p>
                    <p className='mb-0'>
                      <Link to={`tel:${texts.footer.de_office.phone}`}>{ texts.footer.de_office.phone }</Link>
                    </p>
                    <p className='mb-0'>
                      <Link to={`mailto:${texts.footer.de_office.email}`}>{ texts.footer.de_office.email }</Link>
                    </p>
                    <p className='mb-0'>{ texts.footer.de_office.address }</p>
                    <p className='mb-0'>{ texts.footer.de_office.address_2 }</p>
                  </div>
                </div>
                <div className={styles.info}>
                  <p className='mt-4'>
                    <Link to={texts.footer.language_link.url}>{ texts.footer.language_link.title }</Link>
                  </p>
                </div>
              </div>
              <div className={styles.socialIcons}>
                <Link to='https://instagram.com/kosmosandkaos/' >
                  <Icon icon='instagram' />
                </Link>
                <Link to='https://dribbble.com/kosmosandkaos'>
                  <Icon icon='dribbble' />
                </Link>
                <Link to='https://www.facebook.com/kosmosogkaos/'>
                  <Icon icon='facebook' />
                </Link>
                <Link to='https://twitter.com/kosmosandkaos'>
                  <Icon icon='twitter' />
                </Link>
                <Link to='https://www.linkedin.com/company/kosmos-&-kaos'>
                  <Icon icon='linkedin' />
                </Link>
                <Link to='https://open.spotify.com/user/destrosecret/playlist/53X3npsQMR95AkZOE7vYqi?si=HzzAqr2-T5Kjvq_HqpK3ww'>
                  <Icon icon='spotify' />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ReactResizeDetector handleHeight onResize={debounce(this.onResize, 300)} />
      </Fragment>
    )
  }
}

Footer.contextType = LanguageStrings

export default withLanguage(ConsumerWrapper(Footer, ['set', 'windowHeight', 'footerHeight']))
