import React from 'react'
import classnames from 'classnames'
import GatsbyImage from 'gatsby-image'
import styles from './Image.module.scss'
import { ConsumerWrapper } from '../../Context'

class ImageInner extends React.Component {
  constructor () {
    super()
    this.state = {
      currentImage: '',
      imageSizes: []
    }
    this.onResize = this.onResize.bind(this)
    this.setCurrentImage = this.setCurrentImage.bind(this)
  }

  componentWillMount () {
    this.setState({
      currentImage: this.props.image.small ? this.props.image.small.src : '', // set default
      imageSizes: this.props.image
    })
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }

  onResize () {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    const { imageSizes } = this.state

    const setImage = windowWidth > 991
      ? imageSizes.large || imageSizes
      : imageSizes.small || imageSizes

    this.setCurrentImage(setImage)
  }

  setCurrentImage (image) {
    // const { webPSupported } = this.props need to remove from IE and edge
    this.setState({
      currentImage: image.src
    })
  }

  render () {
    const { ratio, image, className, alt, incPadding = true } = this.props
    const useGatsbyImage = false // temp
    const { currentImage } = this.state
    const imageWidth = image.large ? image.large.width : image.width

    return (
      <div
        className={classnames(styles.imageContainer, className)}
        style={{ paddingTop: (imageWidth && incPadding ? `${ratio * 100}%` : '') }}
      >
        {image && image.width && useGatsbyImage &&
          <GatsbyImage
            className={styles.image}
            fixed={image}
            style={{ paddingTop: `${ratio * 100}%` }}
            alt={alt}
          />
        }
        {currentImage &&
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${currentImage})`
            }} />
        }
      </div>
    )
  }
}

const Image = ConsumerWrapper(({ consumerContext: { webPSupported }, ratio, image, className, alt, incPadding = true }) => (
  <ImageInner
    webPSupported={webPSupported}
    ratio={ratio}
    image={image}
    className={className}
    alt={alt}
    incPadding={incPadding}
  />
), ['webPSupported'])

export default Image

Image.defaultProps = {
  image: {},
  ratio: 1.3322981366
}
