import React from 'react'
import Image from 'gatsby-image'
import classnames from 'classnames'
import styles from './InstaPreview.module.scss'
import { Link } from '../'

const InstaPreview = ({ image, likes, id, className }) => {
  return (
    <div className={classnames(styles.container, className)}>
      <Link
        to={'https://www.instagram.com/p/' + id}
        className={styles.link}
      >
        <Image className={styles.image}
          outerWrapperClassName={styles.imageWrapper}
          fixed={image} />
        <span className={styles.likes}><span className={styles.heart}>♥</span> {likes}</span>
      </Link>
    </div>
  )
}

export default InstaPreview
