/* eslint-disabled */
class DeviceSlider {
  constructor (slider, options = {}) {
    this.options = options
    this.pause = false
    this.sliderContainer = slider
    this.sliderItems = []
    this.slides = options.slides || []
    this.framesPerSecond = options.framesPerSecond || 60
    this.initialItemMargin = options.initialItemMargin || 25
    // width of the slider scene
    const itemsWidthWithMargin = () => this.sliderItems.reduce((acc, item) => {
      if (!item.deviceSlider.clone) {
        acc = acc + (item.clientWidth + this.initialItemMargin)
      }
      return acc
    }, 0)
    this.pseudoContainerWidth = () => itemsWidthWithMargin() > this.sliderContainer.clientWidth
      ? itemsWidthWithMargin()
      : this.sliderContainer.clientWidth
    this.itemSpeedMax = options.itemSpeedMax || 5
    this.initialSpeed = 2
    this.currentSpeed = 0
    this.sizeSpeedThrottle = {
      '2': 0.7,
      '1': 0.85,
      '0': 1
    }
    // debug stuff
    window.DeviceSlider = {}
    window.DeviceSlider.pause = () => { this.pause = true }
    window.DeviceSlider.play = () => { this.pause = false }
    this.trackMouse = this.trackMouse.bind(this)
    this.trackOrientation = this.trackOrientation.bind(this)
  }

  createItem (data, options = {}) {
    const deviceWrapper = document.createElement('div')
    const item = document.createElement('div')
    const screen = document.createElement('div')
    const device = document.createElement('div')
    const clone = options.clone || false
    item.className = `cmp-device-slider__item ${data.depth ? `cmp-device-slider__item-depth-${data.depth}` : 'cmp-device-slider__item-depth-0'}`
    deviceWrapper.className = `cmp-device-slider__${data.device}`
    screen.className = `cmp-device-slider__${data.device}-screen`
    device.className = `cmp-device-slider__${data.device}-device`

    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    const imageSrc = windowWidth > 991
      ? data.img.large || data.img
      : data.img.medium || data.img

    screen.style = `background-image: url('${imageSrc}')`
    deviceWrapper.appendChild(screen)
    deviceWrapper.appendChild(device)
    item.appendChild(deviceWrapper)
    item.deviceSlider = {
      ...data,
      clone
    }
    this.sliderContainer.appendChild(item)
    return item
  }

  removeItem (item) {
    this.sliderContainer.removeChild(item)
  }

  createItems (items) {
    this.sliderItems = items.map(item => this.createItem({
      ...item,
      cloneElement: this.createItem(item, { clone: true })
    }))
  }

  init () {
    this.createItems(this.slides)
    this.initItems()
    if (typeof window.orientation !== 'undefined') {
      window.addEventListener('deviceorientation', this.trackOrientation)
    } else {
      document.addEventListener('mousemove', this.trackMouse)
    }
    this.updateLoop()
  }

  destroy () {
    document.removeEventListener('mousemove', this.trackMouse)
    window.removeEventListener('deviceorientation', this.trackOrientation)
  }

  trackMouse (e) {
    const posX = e.clientX
    const width = window.innerWidth
    const centerOffset = (width / 2) - posX
    const maxSpeedToMousePosRatio = this.itemSpeedMax / (width / 2)
    this.currentSpeed = maxSpeedToMousePosRatio * centerOffset
  }

  trackOrientation (e) {
    this.debug = {
      orientation: {
        alpha: e.alpha,
        beta: e.beta,
        gamma: e.gamma
      }
    }
    // Validate environment and event properties.
    if (e.gamma) {
      // tilt range -90, 90
      const gamma = Math.max(Math.min(e.gamma, 90), -90)
      const maxSpeedToOrientationRatio = this.itemSpeedMax / 90
      this.currentSpeed = maxSpeedToOrientationRatio * gamma
    }
  }

  initItems () {
    let lastPos = 0
    this.sliderItems.map((item, index) => {
      const posX = lastPos
      lastPos = item.clientWidth + lastPos + this.initialItemMargin
      item.deviceSlider.posX = posX

      item.style.transform = `translate3d(${posX}px, -50%, 0)`
      return item
    })
  }

  moveItems () {
    this.sliderItems.map(item => {
      const containerWidth = this.pseudoContainerWidth()
      const prevPosX = item.deviceSlider.posX
      const throttle = item.deviceSlider.depth
        ? this.sizeSpeedThrottle[item.deviceSlider.depth]
        : 1
      let nextPosX = prevPosX + (this.currentSpeed * throttle)
      // Clone is located outside slider container while not needed
      let clonePosX = -item.clientWidth
      // if item is leaving from the rigth, clone enters from the left & vice versa
      if (nextPosX + item.clientWidth > containerWidth) {
        clonePosX = nextPosX - containerWidth
      } else if (nextPosX < 0) {
        clonePosX = containerWidth + nextPosX
      }
      if (nextPosX + item.clientWidth < 0 || nextPosX > containerWidth) {
        // if original item is fully out of view, we switch the orginal with the clone
        [nextPosX, clonePosX] = [clonePosX, nextPosX]
      }
      item.deviceSlider.posX = nextPosX
      item.deviceSlider.cloneElement.style.transform = `
          translate3d(
            ${clonePosX}px,
            -50%,
            0
          )`
      item.style.transform = `
          translate3d(
            ${nextPosX}px,
            -50%,
            0
          )`
    })
  }

  updateLoop () {
    // Use var then = Date.now() if you
    // don't care about targetting < IE9
    let then = new Date().getTime()
    // custom fps, otherwise fallback to 60
    const interval = 1000 / this.framesPerSecond

    const loop = time => {
      window.requestAnimationFrame(loop)
      const now = new Date().getTime()
      const delta = now - then
      if (delta > interval) {
        then = now - (delta % interval)
        if (!this.pause) {
          this.moveItems()
        }
        if (this.options.onUpdate) {
          this.options.onUpdate()
        }
      }
    }
    return loop(0)
  }
}

export default DeviceSlider
