export default {
  showMenu: false,
  bgColor: undefined,
  logoStopValue: undefined,
  logoScrollColors: [],
  logoColor: undefined,
  navigationTransparent: false,
  thirdDimensionActive: false,
  footerHeight: undefined,
  windowHeight: undefined,
  windowWidth: undefined,
  webPSupported: true,
  set: () => { }
}
