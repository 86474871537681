import React from 'react'
import classnames from 'classnames'
import styles from './AuthorTag.module.scss'
import { formatDate } from '../../misc/utilities'

const AuthorTag = ({ avatar, name, date, className }) => {
  return (
    <div className={classnames(styles.container, className)}>
      <div
        className={styles.authorImg}
        style={{ backgroundImage: `url(${avatar})` }} />
      <div>
        <p className={styles.authorName}>{name}</p>
        <p className={styles.date}>
          {formatDate(date)}
        </p>
      </div>
    </div>
  )
}

export default AuthorTag
