import React from 'react'
import styles from './Button.module.scss'
import classnames from 'classnames'
import { Link } from '../'

const Button = ({ children, className, to, ...props }) => {
  if (to) {
    return (
      <Link className={classnames(styles.container, className)} to={to} {...props}>
        {children}
      </Link>
    )
  }
  return (
    <button className={classnames(styles.container, className)} {...props}>
      {children}
    </button>
  )
}

export default Button
