import React from 'react'
import classNames from 'classnames'
import Swiper from 'swiper'
import styles from './Slider.module.scss'
import ReactResizeDetector from 'react-resize-detector'

class Slider extends React.Component {
  constructor () {
    super()

    this.onResize = this.onResize.bind(this)
  }

  componentDidMount () {
    this.swiper = new Swiper(
      this.scrollContainer,
      { ...this.props }
    )
  }

  componentWillUnmount () {
    this.swiper.destroy()
  }

  onResize () {
    this.swiper.update()
  }

  render () {
    const {
      children
    } = this.props

    return (
      <div className={styles.container}>
        <div
          ref={el => { this.scrollContainer = el }}
          className={classNames('swiper-container', 'container', styles.swipeContainer)}>

          <div className={classNames('swiper-wrapper', styles.wrapper)}>
            {children}
          </div>
        </div>

        {/* bootstrap styles race condition fix */}
        <ReactResizeDetector handleWidth onResize={this.onResize} />
      </div>
    )
  }
}

/* https://idangero.us/swiper/api/#parameters */
Slider.defaultProps = {
  slidesPerView: 4,
  slidesPerGroup: 4,
  spaceBetween: 10,
  grabCursor: true,
  freeMode: true,
  freeModeMomentumBounce: false,
  freeModeMomentumRatio: 0.5,
  mousewheel: {
    forceToAxis: true
  },
  breakpoints: {
    768: {
      slidesPerView: 1.5
    }
  }
}

export default Slider
